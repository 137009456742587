import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "page-layout" */ '@/modules/pages/layouts/PageLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "landing-view" */ '@/modules/pages/views/LandingPageView.vue'),
      },
      {
        path: 'sobre-mi',
        name: 'sobre-mi',
        component: () => import(/* webpackChunkName: "sobre-mi" */ '@/modules/pages/views/AboutView.vue'),
      },
      {
        path: 'medios',
        name: 'medios',
        component: () => import(/* webpackChunkName: "medios" */ '@/modules/pages/views/MediaView.vue'),
      },
      {
        path: 'cursos',
        name: 'cursos',
        redirect: { name: 'comunicacion-politica-avanzada' }
      },
      {
        path: 'comunicacion-politica-avanzada',
        name: 'comunicacion-politica-avanzada',
        component: () => import(/* webpackChunkName: "discurso-view" */ '@/modules/pages/views/DiscursoPoliticoView.vue'),
      },
      {
        path: 'negociacion-y-metodos-persuasivos',
        name: 'negociacion-y-metodos-persuasivos',
        component: () => import(/* webpackChunkName: "negociacion-view" */ '@/modules/pages/views/NegociacionView.vue'),
      },
      {
        path: 'contacto',
        name: 'contacto',
        component: () => import(/* webpackChunkName: "contacto" */ '@/modules/pages/views/ContactView.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
